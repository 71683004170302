<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="Nuevo Estado..." icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
						<Button label="Exportar a Excel"  class="p-button-warning  p-mr-2" v-on:click="download" />
						<!-- <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedEstados || !selectedEstados.length" /> -->
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="estados" v-model:selection="selectedEstados" 
				:key="estadoKey" dataKey="clave_estado" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords} estados">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Catálogo de Estados</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>

					<!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
					
                    <Column field="clave_pais" header="Pais" :sortable="true"></Column>
					<Column field="estado" header="Estado" :sortable="true"></Column>
					<Column field="clave_estado" header="Clave" :sortable="true"></Column>
                    <Column field="activo" header="Activo" :sortable="true">
						<template #body="slotProps">
                                    {{turno(slotProps.data.activo)}}
                        </template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editEstado(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteEstado(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="estadoDialog" :style="{width: '450px'}" header="Estado" :modal="true" class="p-fluid">
                    <div class="p-field">
						<label for="clave">Clave *</label>
						<InputText id="clave" v-model.trim="estado.clave_estado" required="true" autofocus :class="{'p-invalid': submitted && !estado.clave}" />
						<small class="p-invalid" v-if="submitted && !estado.clave_estado">La clave del estado es requerido</small>
					</div>

                    <div class="p-field">
						<label for="pais">Pais *</label>
						<Dropdown id="pais" name="pais" v-model="estado.clave_pais" :options="paises" optionLabel="clave_pais" placeholder="Selecionar un pais ..." />
					</div>

					<div class="p-field">
						<label for="estado">Estado *</label>
						<InputText id="estado" v-model.trim="estado.estado" required="true" autofocus :class="{'p-invalid': submitted && !estado.estado}" />
						<small class="p-invalid" v-if="submitted && !estado.estado">El nombre de la estado es requerido</small>
					</div>

                    <div class="p-field-checkbox">
                        <Checkbox id="activo" name="activo"  :binary="true" v-model="estado.activo" />
                        <label for="activo">Activo</label>
                    </div>

					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveEstado" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteEstadoDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="estado">¿Desea eliminar el estado <b>{{estado.estado}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteEstadoDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteEstado" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteEstadosDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="estado">¿Desea eliminar los estados seleccionados?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteEstadosDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteSelectedEstados" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import EstadoService from '../service/EstadoService';
import XLSX from 'xlsx'
export default {
	data() {
		return {
			estadoKey: 0,
			estados: null,
			estadoDialog: false,
			deleteEstadoDialog: false,
			deleteEstadosDialog: false,
			estado: {},
			paises:null,
			estadoactivo: false,
			selectedEstados: null,
			filters: {},
			submitted: false,
			EstadoSub:[],
            dropdownValues: [
				{pais: 'MEX'},
				{pais: 'USA'},
                {pais: 'CAN'}
			],
			id_estado:null,
			activo:null,
			clave_estado:null,
			clave_pais:null,
			estadonombre:null,
			ClaveModelPais:'',
			EstadosExpor:null,
		}
	},
	estadoService: null,
	created() {
		this.estadoService = new EstadoService();
	},
	mounted() {
		this.estadoService.getEstados().then(data => this.estados = data);
		this.estadoService.getPaises().then(data => this.paises = data);
	
	},
	methods: {
		forceRerender() {
      		this.estadoKey += 1;
    	},
		openNew() {
			this.estado = {};
			this.submitted = false;
			this.estadoDialog = true;
			this.estado.activo=true;
		},
		hideDialog() {
			this.estadoDialog = false;
			this.submitted = false;
		},
		saveEstado() {
			this.submitted = true;
           
			if (this.estado.estado.trim()) {
				if (this.estado.id_estado>0) {

					this.EstadoSub.push({
						"id_estado":this.estado.id_estado,
						"activo":this.estado.activo,
						"clave_estado":this.estado.clave_estado,
						"clave_pais":this.estado.clave_pais.clave_pais,
						"estado":this.estado.estado,
					});
				 
					this.estadoService.updateEstado(this.EstadoSub[0]).then();
					
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Estado actualizado', life: 3000});
					this.EstadoSub=[];					
					document.location.reload();
					 
				}
				else {
					 
					this.EstadoSub.push({
						"activo":this.estado.activo,
						"clave_estado":this.estado.clave_estado,
						"clave_pais":this.estado.clave_pais.clave_pais,
						"estado":this.estado.estado,
					});
					 
					this.estadoService.createEstado(this.EstadoSub[0]).then();
					
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Estado creado', life: 3000});
					this.EstadoSub=[];			
					 document.location.reload();
					 
				}

				this.estadoDialog = false;
				this.estado = {};
			}
		},
		editEstado(estado) {
			this.estado = {...estado};		
			this.estadoService.getEstado(estado).then(data => {
					 this.estado = data;
					 this.ClaveModelPais = data.clave_pais;
					for(var i in this.paises){
						if(this.ClaveModelPais==this.paises[i].clave_pais){
							 
							this.estado.clave_pais = this.paises[i];
						}
                    	
                	} 
			});
			this.estadoDialog = true; 
		},
		confirmDeleteEstado(estado) {
			this.estado = estado;
			this.deleteEstadoDialog = true;
		},
		deleteEstado() {
			this.EstadoSub.push({
				"id_estado":this.estado.id_estado,
				"activo":0,
				"clave_estado":this.estado.clave_estado,
				"clave_pais":this.estado.clave_pais.clave_pais,
				"estado":this.estado.estado,
			});
				 
			this.estadoService.updateEstado(this.EstadoSub[0]).then()
			// this.estadoService.deleteEstado(this.estado).then();
			this.deleteEstadoDialog = false;
			 				
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Estado eliminado', life: 3000});
			document.location.reload();
			 
		},
		findIndexById(clave) {
			let index = -1;
			for (let i = 0; i < this.estados.length; i++) {
				if (this.estados[i].clave === clave) {
					index = i;
					break;
				}
			}

			return index;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteEstadosDialog = true;
		},
		deleteSelectedEstados() {
			this.estados = this.estados.filter(val => !this.selectedEstados.includes(val));
			console.log(this.estados);
			this.deleteEstadosDialog = false;
			this.selectedEstados = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Estados eliminados', life: 3000});
		},
		turno(value){
			if(value ==true){
				return 'Activo'
			}else{
				return 'Inactivo'
			}
		},
		download : function() {
			this.estadoService.getEstadosExport().then(data => {
			this.EstadosExpor = data
				if(this.EstadosExpor != null){
				const data = XLSX.utils.json_to_sheet(this.EstadosExpor)
				const wb = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(wb, data, 'data')
				XLSX.writeFile(wb,'CatalogoEstados.xlsx')
			}
			});
		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.estado-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .estado-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.estado-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-activo {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-inactivo {
		background: #FFCDD2;
		color: #C63737;
	}
}
</style>
