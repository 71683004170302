import axios from 'axios';

export default class EstadoService {

	getEstados() {
		//return axios.get('http://localhost:8000/api/estados').then(res => res.data.data);
		//const url = `${API_URL}/api/estados`;
		//const url = ` https://sam.apicontrol.website/api/api/estados`;
		const url = `/api/estados`;
		return axios.get(url).then(res=>res.data.data);
	}

	getEstado(estado) {
		//return axios.get('http://localhost:8000/api/estados').then(res => res.data.data);
		//const url = `${API_URL}/api/estados/${estado.clave}`;
		//const url = ` https://sam.apicontrol.website/api/api/estados/${estado.id_estado}`;
		const url = `/api/estados/${estado.id_estado}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEstado(estado){
		//const url = `${API_URL}/api/estados/${estado.clave}`;
		//const url = ` https://sam.apicontrol.website/api/api/estados/${estado.id_estado}`;
		const url = `/api/estados/${estado.id_estado}`;
		return axios.put(url,estado).then(res=>res.data.data);
	}

	createEstado(estado){
		//const url = `${API_URL}/api/estados`;
		//const url = ` https://sam.apicontrol.website/api/api/estados`;
		const url = `/api/estados`;
		return axios.post(url,estado).then(res=>res.data.data);
	}

	deleteEstado(estado){
		//const url = `${API_URL}/api/estados/${estado.clave}`;
		//const url = ` https://sam.apicontrol.website/api/api/estados/${estado.id_estado}`;
		const url = `/api/estados/${estado.id_estado}`;
		return axios.delete(url).then(res=>res.data.data);
	}

	getPaises() {
		//return axios.get('assets/layout/data/paises.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/paises`;
		const url = `/api/paises`;
		
		return axios.get(url).then(res=>res.data.data);
	}

	getEstadosExport() {
		//return axios.get('http://localhost:8000/api/estados').then(res => res.data.data);
		//const url = `${API_URL}/api/estados`;
		//const url = ` https://sam.apicontrol.website/api/api/estadosexport`;
		const url = `/api/estadosexport`;
		return axios.get(url).then(res=>res.data.data);
	}
}